import React from 'react'

import { MainTemplate } from 'src/components/templates/MainTemplate'
import { Main } from 'src/components/offer/main/Main'
import OfferServicesSection from 'src/components/organisms/OfferServicesSection/OfferServicesSection'
import OfferDeliverySection from 'src/components/organisms/OfferDeliverySection/OfferDeliverySection'
import OfferExamplesSection from 'src/components/organisms/OfferExamplesSection/OfferExamplesSection'

import SEO from '../components/seo/seo'

const IndexPage = () => (
  <MainTemplate>
    <SEO description='dsafagaeggr' title='Dowiedz się czym się zajmujemy | Kuliberda.tech' />
    <Main />
    <OfferServicesSection />
    <OfferDeliverySection />
    <OfferExamplesSection />
  </MainTemplate>
)

export default IndexPage
