import styled from 'styled-components'

import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'

import { device } from 'src/themes/breakpoints'

export const Section = styled.section`
  margin-top: 5rem;

  @media ${device.mobileXl} {
    margin-top: 15%;
  }

  @media ${device.tablet} {
    margin-top: 23%;
  }

  @media ${device.laptop} {
    margin-top: 15%;
  }

  @media ${device.laptopM} {
    margin-top: 15%;
  }

  @media ${device.laptopL} {
    margin-top: 10%;
  }
`

export const Heading = styled(HeadingComponent)`
  text-align: center;
  margin-bottom: 3rem;

  @media ${device.mobileXl} {
    margin-bottom: 0rem;
  }
`
