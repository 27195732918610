import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import * as S from './styles'

const OfferDeliverySection = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulOffer {
          deliveryHeader
          deliveryParagraph
        }
        contentfulAsset(title: { eq: "sketch" }) {
          file {
            url
          }
        }
      }
    `}
    render={data => {
      const { deliveryHeader, deliveryParagraph } = data.contentfulOffer
      const { url } = data.contentfulAsset.file

      return (
        <S.Wrapper as='section'>
          <S.Container>
            <S.Heading as='h2' h2>
              {deliveryHeader}
            </S.Heading>
            <S.MobileImage src={url} alt='' />

            <S.Paragraph>{deliveryParagraph}</S.Paragraph>
            <S.Button to='/kontakt'>Skontaktuj się &gt;</S.Button>
          </S.Container>
          <S.DesktopImage src={url} alt='' />
        </S.Wrapper>
      )
    }}
  />
)

export default OfferDeliverySection
