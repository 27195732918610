import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { ServiceArticle } from './ServiceArticle'

import * as S from './styles'

const HomeOfferSection = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulServices {
          nodes {
            image {
              fluid {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            title
            reversed
            link
            description
          }
        }
      }
    `}
    render={data => {
      const offers = data.allContentfulServices.nodes

      return (
        <S.Section>
          <S.Heading as='h2' h2>
            Oferta
          </S.Heading>
          {offers.map(offer => (
            <ServiceArticle key={offer.title} {...offer} />
          ))}
        </S.Section>
      )
    }}
  />
)

export default HomeOfferSection
