import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'
import { Heading } from 'src/components/common/heading/Heading'
import { Button } from 'src/components/common/button/Button'
import settingsDark from 'src/images/settings-horizontal-dark.svg'

const StyledDelivery = styled.section`
  background: url(${settingsDark}) no-repeat center;
  background-size: auto 100%;
  padding: 8rem 0 5rem;
`

const StyledHeader = styled(Heading)`
  text-align: center;
  margin-bottom: 3rem;
`

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const OfferExamplesSection = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulOffer {
          examplesHeader
        }
      }
    `}
    render={data => {
      const { examplesHeader } = data.contentfulOffer

      return (
        <StyledDelivery>
          <Wrapper>
            <StyledHeader as='h2' h2>
              {examplesHeader}
            </StyledHeader>
            <StyledButton to='/galeria'>Galeria &gt;</StyledButton>
          </Wrapper>
        </StyledDelivery>
      )
    }}
  />
)

export default OfferExamplesSection
