import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { MainSection } from 'src/components/common/mainSection/MainSection'

export const Main = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulOffer {
          mainHeader
          mainParagraph
        }
        allContentfulAsset(filter: { title: { in: ["web-offer-hero", "mobile-offer-hero"] } }) {
          nodes {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const { mainHeader, mainParagraph } = data.contentfulOffer
      const images = data.allContentfulAsset.nodes

      return <MainSection images={images} heading={mainHeader} paragraph={mainParagraph} page='offer' />
    }}
  />
)
