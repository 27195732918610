import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Paragraph } from 'src/components/common/paragraph/Paragraph'
import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'

import { device } from 'src/themes/breakpoints'

const Article = styled.article`
  @media ${device.mobileXl} {
    padding: 5rem 0;
    background: ${({ reversed, theme }) => (reversed ? theme.colors.primaryWhite : theme.colors.bgGray)};
  }
`

const Wrapper = styled(WrapperComponent)`
  @media ${device.mobileXl} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
  }
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 3rem;

  span {
    font-weight: ${({ theme }) => theme.fonts.weight.semi};
  }

  @media ${device.mobileXl} {
    text-align: ${({ reversed }) => (reversed ? 'right' : 'left')};
    margin: 0;
  }

  @media ${device.mobileXl} {
    flex-basis: 40%;
  }
`

const StyledImage = styled(Image)`
  margin: 0 auto 5rem;
  max-width: 400px;

  @media ${device.mobileXl} {
    margin: 0;
    flex-basis: 50%;
  }
`

export const ServiceArticle = ({ title, description, image, reversed }) => (
  <Article as='article' reversed={reversed}>
    <Wrapper reversed={reversed}>
      <StyledParagraph reversed={reversed}>
        <span>{title}</span> {description}
      </StyledParagraph>
      <StyledImage fluid={image.fluid} />
    </Wrapper>
  </Article>
)

ServiceArticle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
  reversed: PropTypes.bool.isRequired
}
