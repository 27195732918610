import styled from 'styled-components'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'
import { Button as ButtonComponent } from 'src/components/common/button/Button'

import { device } from 'src/themes/breakpoints'

export const Wrapper = styled(WrapperComponent)`
  @media ${device.mobileXl} {
    margin: 8rem auto 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const Container = styled.div`
  @media ${device.mobileXl} {
    flex-basis: 50%;
    text-align: left;
  }
`

export const Heading = styled(HeadingComponent)`
  text-align: center;
  margin-bottom: 3rem;

  @media ${device.mobileXl} {
    text-align: left;
    margin-bottom: 4.5rem;
  }
`

export const MobileImage = styled.img`
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 5rem;

  @media ${device.mobileXl} {
    display: none;
  }
`

export const DesktopImage = styled.img`
  display: none;

  @media ${device.mobileXl} {
    flex-basis: 40%;
    display: block;
    max-width: 300px;
  }
`

export const Paragraph = styled(ParagraphComponent)`
  text-align: center;
  margin-bottom: 3rem;

  @media ${device.mobileXl} {
    text-align: left;
    margin-bottom: 4.5rem;
  }
`

export const Button = styled(ButtonComponent)`
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.bgBlue};

  @media ${device.mobileXl} {
    margin: 0;
  }
`
